export const BACK_ORDERS_ROUTE = '/backorders';

import { TITLE_PREFIX, ORDERS_INDEX_ROUTE, ORDERS_DETAILS_ROUTE } from '../../../modules/orders/consts';
import { PRODUCTS_DETAILS_ROUTE } from '../../../modules/products/consts';
import UserModel from '../../../../UserModel';

export default {
    name: 'backOrderGridPage',
    before: [],
    after: [],
    setup: (app) => {
        const route = BACK_ORDERS_ROUTE;
        const label = 'Backorders';
        const permission = 'orders.index';

        app.routes.addItem('backOrderGridRoute', {
            path: route,
            title: label,
            permission,
            props: () => ({
                permissions: {
                    withOrderLink: UserModel.getCurrentUser().hasAnyPermissions('orders.show'),
                    withProductLink: UserModel.getCurrentUser().hasAnyPermissions('products.show'),
                },
                routes: {
                    orderDetailsPageRoute: ORDERS_DETAILS_ROUTE,
                    productDetailsPageRoute: PRODUCTS_DETAILS_ROUTE,
                },
            }),
            loader: () => import('./packages/BackOrderGrid/core'),
        });

        const sideMenuName = 'orders';
        const sideMenuItem = app.sideMenuItems.getItem(sideMenuName);
        if (sideMenuItem) {
            app.sideMenuItems.changeItem(sideMenuName, {
                ...sideMenuItem,
                items: [
                    {
                        label: TITLE_PREFIX,
                        link: ORDERS_INDEX_ROUTE,
                        icon: 'orderStatus',
                    },
                    {
                        label: label,
                        link: BACK_ORDERS_ROUTE,
                        icon: 'ArrowReturnLeft',
                    },
                ],
            });
        }
    },
};
