export default {
    name: 'printUpdate',
    before: [],
    after: [],
    setup: (app) => {
        app.routes.addMany({
            pickupPackingSlipPrint: {
                path: `/fulfillment-pickup/packing-slip/:id`,
                title: `Fulfillments Pickup Print`,
                permission: ['fulfillments.show'],
                breadcrumbConfig: {
                    name: `Fulfillments Pickup Print`,
                },
                props: () => ({}),
                loader: () => import('@skunexus/pickup-packing-slip-print'),
            },
            shipmentPrint: {
                path: '/shipment-print/:id',
                title: `Shipment Print`,
                permission: ['fulfillments.show'],
                breadcrumbConfig: {
                    name: `Shipment Print`,
                },
                props: () => ({}),
                loader: () => import('@skunexus/shipment-print'),
            },
        });

        const shipmentName = 'shipment';
        const shipmentRoute = app.routes.getItem(shipmentName);
        if (shipmentRoute) {
            const routeProps = shipmentRoute.props;
            shipmentRoute.props = () => ({
                ...routeProps,
                shipmentPrintPageRoute: '/shipment-print',
            });

            app.routes.changeItem(shipmentName, shipmentRoute);
        }
    },
};
