import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { actions as notification } from '@skunexus/notification';
import { ajaxExceptionMessage } from '@skunexus/utils';
import { Spinner } from '@skunexus/component-catalog';

import * as API from '../api';

export const withAuthorizationHoc =
    ({ applyAlert = () => {} } = {}) =>
    (Component) => {
        function WrappedComponent(props) {
            const { params, errorNotification = () => {} } = props;
            const [isAuthorizing, setAuthorizing] = useState(true);

            const checkAuthorization = async ({ fulfillmentId = '' } = {}) => {
                setAuthorizing(true);
                try {
                    await API.authorizePack({ fulfillmentId });
                    setAuthorizing(false);
                } catch (e) {
                    errorNotification(ajaxExceptionMessage(e));

                    setAuthorizing(false);
                    setTimeout(() => {
                        applyAlert();
                    }, 1500);
                }
            };

            useEffect(() => {
                const { id } = params;
                if (id) {
                    checkAuthorization({ fulfillmentId: id });
                }
            }, [params?.id]);

            if (isAuthorizing) {
                return <Spinner className="mt-3" />;
            }

            return <Component {...props} />;
        }

        const mapDispatchToProps = {
            errorNotification: notification.error,
            successNotification: notification.success,
        };

        return connect(null, mapDispatchToProps)(WrappedComponent);
    };
