import { addNotesToAttributesListInDetails } from '../../common/utils';

export default {
    name: 'fulfillmentShipDetailsPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'fulfillmentShipDetails';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                infoProps: {
                    customAttributeList: addNotesToAttributesListInDetails,
                },
                shipmentPrintPageRoute: '/shipment-print',
            });

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
