import { withAuthorizationHoc } from '../../common/HOCs/PackingAuthorizationHoc';
import { addNotesToAttributesListInDetails } from '../../common/utils';

export default {
    name: 'fulfillmentPickupPackingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'pickupPacking';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                infoProps: {
                    customRightAttributesList: addNotesToAttributesListInDetails,
                },
            });

            const applyAlert = () => {
                const alert = document.createElement('div');
                alert.className = 'text-center alert alert-danger fade show';
                alert.textContent = 'Packing process is disabled for that fulfillment.';

                const content = document.querySelector('[class*="sn-cc_pageContent"] .container-fluid');
                if (content) {
                    const children = content.children;
                    if (children.length >= 2) {
                        const secondDiv = children[1];
                        secondDiv.replaceWith(alert);
                    }
                }
            };
            pageRoute.render = withAuthorizationHoc({ applyAlert });
            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
