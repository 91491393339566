import { withAuthorizationHoc } from '../../common/HOCs/GroupPickingAuthorizationHoc';

export default {
    name: 'fulfillmentPickupGroupPickingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'pickupGroupPicking';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            pageRoute.render = withAuthorizationHoc();
            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
