import { withAuthorizationHoc } from '../../common/HOCs/PickingAuthorizationHoc';
import { addNotesToAttributesListInDetails } from '../../common/utils';

export default {
    name: 'fulfillmentShipPickingPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'fulfillmentShipPicking';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                infoProps: {
                    customRightAttributesList: addNotesToAttributesListInDetails,
                },
            });
            pageRoute.render = withAuthorizationHoc();
            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
