import axios from 'axios';
import { constants } from '@skunexus/utils';

const { API_PATH } = constants.CONFIG;

export function authorizePick({ fulfillmentId }) {
    return axios.post(`${API_PATH}/phg/fulfillments/${fulfillmentId}/authorize-pick`);
}

export function authorizeGroupPick({ fulfillmentGroupId }) {
    return axios.post(`${API_PATH}/phg/fulfillments/${fulfillmentGroupId}/authorize-group-pick`);
}

export function authorizePack({ fulfillmentId }) {
    return axios.post(`${API_PATH}/phg/fulfillments/${fulfillmentId}/authorize-pack`);
}
