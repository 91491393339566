export default {
    name: 'orderDetailsPage',
    before: [],
    after: [],
    setup: (app) => {
        const pageName = 'orderDetails';
        const pageRoute = app.routes.getItem(pageName);
        if (pageRoute) {
            const shipmentGridCustomGridData = (gridProps) => {
                const rowOptions = [...(gridProps.config.rowOptions || [])].filter(({ id }) => id !== 'reship');

                return {
                    ...gridProps,
                    columns: [...(gridProps.columns || [])].filter(({ field }) => field !== 'reship_fulfillment_id'),
                    config: {
                        ...(gridProps.config || {}),
                        rowOptions,
                        withRowOptions: !!rowOptions.length,
                    },
                };
            };

            const routeProps = pageRoute.props;
            pageRoute.props = () => ({
                ...routeProps(),
                shipmentGridCustomGridData,
            });

            app.routes.changeItem(pageName, pageRoute);
        }
    },
};
